import React from 'react'
import MemphisPattern from '@components/MemphisPattern'
import HeroBoxed from '@widgets/HeroBoxed'
import { Box, Heading, Button, Donut, Card } from 'theme-ui'
import { Link } from 'gatsby'
import Divider from '@components/Divider/Divider'
import { SearchBox } from 'react-instantsearch-dom'
const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
}


const Sezionepage = () => (
  <>
  <HeroBoxed.Wrapper>
  


<div style={{width:"100%",  textAlign:"center"}}>
  
<Box sx={styles.wrapper}> 
<Heading>Abbiamo 2 tipi di commissioni:</Heading>
<h2>Se non vinciamo, non ci devi nulla</h2> 
</Box>

<Card variant='paper'style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)'}}>
<h2>Commissione Standard</h2> 
<h3>Riceverai il 70% del risarcimento</h3> 
   
   <Donut value={1 / 1.4 } style={{color:"green",}} />
   <p>commissione standard del <b>30%</b></p>
   <p>esempio su 250€ prenderemo 75€</p>
   
   
   
   </Card>
   <Divider></Divider>
   
   <Card variant='paper'style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)'}}>
   <h2>Commissione con Azione Legale</h2> 
   <h3>Riceverai il 50% del risarcimento</h3> 
   <Donut value={1 / 2 } style={{color:"green",}} />
   <p>commissione con azione legale <b>50%*</b></p>
   <p>esempio: su 250€ prenderemo 125€</p>
   </Card>
   </div>  

 
  

    



    
   </HeroBoxed.Wrapper>
 
  
  </>
)


const testimonialQuery = graphql`
  query TestimonialQuery {
    file(absolutePath: { regex: "/testimonial.(jpeg|jpg|gif|png)/" }) {
      publicURL
    }
  }
  `
export default Sezionepage
